import React from 'react'

export default class NewsFilter extends React.Component {
    constructor() {
        super();
        this.handleClickAll = this.handleClickAll.bind(this);
        this.handleClickUpdates = this.handleClickUpdates.bind(this);
        this.handleClickFeatures = this.handleClickFeatures.bind(this);
        this.handleClickBugs = this.handleClickBugs.bind(this);
        this.state = {
            isClickedAll: true,
            isClickedUpdates: false,
            isClickedFeatures: false,
            isClickedBugs: false
        }
    }
    
    componentDidMount() {
        // var _this = this;
        if (typeof window !== `undefined`) {

            // import Isotope API
            const Isotope = require("isotope-layout/js/isotope");
            // init Isotope
            this.iso = new Isotope(`#notes_filter`, {
              itemSelector: `.item`,
            });
        }
    }

    handleClickAll(e) {
        this.setState({
            isClickedAll: true,
            isClickedUpdates: false,
            isClickedFeatures: false,
            isClickedBugs: false,
        });
        if (this.iso === undefined) {
            const Isotope = require("isotope-layout/js/isotope");
            this.iso = new Isotope(`#notes_filter`, {
                itemSelector: `.item`,
            });
        }
        this.iso.arrange({ filter: `*` });
    }
    handleClickUpdates(e) {
        this.setState({
            isClickedAll: false,
            isClickedUpdates: true,
            isClickedFeatures: false,
            isClickedBugs: false,
        });
        if (this.iso === undefined) {
            const Isotope = require("isotope-layout/js/isotope");
            this.iso = new Isotope(`#notes_filter`, {
                itemSelector: `.item`,
            });
        }
        this.iso.arrange({ filter: `.up` });
    }
    handleClickFeatures(e) {
        this.setState({
            isClickedAll: false,
            isClickedUpdates: false,
            isClickedFeatures: true,
            isClickedBugs: false,
        });
        if (this.iso === undefined) {
            const Isotope = require("isotope-layout/js/isotope");
            this.iso = new Isotope(`#notes_filter`, {
                itemSelector: `.item`,
            });
        }
        this.iso.arrange({ filter: `.feat` });
    }
    handleClickBugs(e) {
        this.setState({
            isClickedAll: false,
            isClickedUpdates: false,
            isClickedFeatures: false,
            isClickedBugs: true,
        });
        if (this.iso === undefined) {
            const Isotope = require("isotope-layout/js/isotope");
            this.iso = new Isotope(`#notes_filter`, {
                itemSelector: `.item`,
            });
        }
        this.iso.arrange({ filter: `.bug` });
    }

    render() {
        return (
            <div id="notestab_filter" className="job_list_tab">
                <div data-filter="*" className={`list_item_tab ${(this.state.isClickedAll) ? 'active' : ''}`} role="button" tabIndex="-1" onClick={this.handleClickAll} onKeyDown={this.handleClickAll}>All</div>
                <div data-filter=".up" className={`list_item_tab update ${(this.state.isClickedUpdates) ? 'active' : ''}`} role="button" tabIndex="-1" onClick={this.handleClickUpdates} onKeyDown={this.handleClickUpdates}>Updates</div>
                <div data-filter=".feat" className={`list_item_tab feature ${(this.state.isClickedFeatures) ? 'active' : ''}`} role="button" tabIndex="-1" onClick={this.handleClickFeatures} onKeyDown={this.handleClickFeatures}>Features</div>
                <div data-filter=".bug" className={`list_item_tab bug ${(this.state.isClickedBugs) ? 'active' : ''}`} role="button" tabIndex="-1" onClick={this.handleClickBugs} onKeyDown={this.handleClickBugs}>Bugs</div>
            </div>
        )
    }
}