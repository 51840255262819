import React from 'react';
import Layout from '../../components/layouts';
import Banner from '../../components/about/news/Banner'
import News from '../../components/about/news/News'
import { graphql } from 'gatsby';
import SEO from '../../components/common/SEO'

export const query = graphql`
{
    prismic {
        allNewss(sortBy: date_DESC) {
        edges {
            node {
            _meta {
                uid
            }
            categories {
                category
            }
            content
            date
            title
            }
        }
    }
  }
}
`
const NewsPage = props => {

  const seo = {
    title: "Workhub News | Release Notes | Free EHS & Compliance Management Software",
    description:
      "Keep up with the latest & greatest Feature additions, improvements & changes we make to our Free EHS & Compliance Management Software",
  }


  return(
    <Layout>
       <SEO {...seo}/>
        <Banner/>
        <News {...props}/>
    </Layout>
  )
}

export default NewsPage;
