import React from 'react';
import { Link } from 'gatsby'
const Banner = () => {
    return(
        <section className="breadcrumb_area_two">
            <ul className="list-unstyled bubble">
                {/* <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li> */}
            </ul>
            <div className="breadcrumb_shap"></div>
            <div className="container">
                <div className="breadcrumb_content_two text-center">
                    {/* <ol className="breadcrumb">
                        <li><Link to="/">Resources</Link></li>
                        <li className="active">What's New</li>
                    </ol> */}
                    <h1 className="f_p f_700 f_size_50 w_color l_height50 mb_20">Release Notes</h1>

                </div>
            </div>
        </section>
    )
}

export default Banner

